@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import 'node_modules/@swimlane/ngx-datatable/index.css';
@import 'node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import 'node_modules/@swimlane/ngx-datatable/assets/icons.css';

@tailwind base;

@layer base {
  html {
    @apply text-[#4f4f4f];
  }

  html.dark {
    @apply text-neutral-50;
    @apply bg-neutral-800;
  }
}

@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.icon-on-hover:hover {
  filter: brightness(60%);
  transition: filter 0.1s ease;
}

h1 {
  @apply text-2xl lg:text-4xl;
}

h2 {
  @apply text-xl lg:text-3xl;
}

h3 {
  @apply text-lg lg:text-2xl;
}

h4 {
  @apply text-base lg:text-xl;
}

blockquote {
  @apply p-4 pl-6 border-l-4 border-gray-500 italic text-lg text-gray-800;
}

blockquote footer {
  @apply mt-2 text-sm text-gray-600;
}

ul,
ol {
  @apply pl-6 space-y-2 text-gray-800;
}

ul li,
ol li {
  @apply text-base leading-relaxed;
}

ul {
  @apply list-disc;
}

ol {
  @apply list-decimal;
}

figure.table {
  @apply overflow-x-auto bg-gray-100 rounded-lg shadow-md p-4;
}

figure.table table {
  @apply w-full border-collapse border border-gray-300 text-left text-sm text-gray-700;
}

figure.table tbody {
  @apply divide-y divide-gray-300;
}

figure.table tr {
  @apply hover:bg-gray-50;
}

figure.table td,
figure.table th {
  @apply px-4 py-2 border border-gray-300;
}

figure.table th {
  @apply bg-gray-200 font-semibold text-gray-800;
}

figure.image.image-style-align-right {
  @apply float-right ml-4 mb-4 max-w-xs;
}

figure.image.image-style-align-left {
  @apply float-left mr-4 mb-4 max-w-xs;
}

figure.image img {
  @apply rounded shadow-lg;
}